import React from 'react';

import Layout from 'components/layout';
import SEO from 'components/seo';
import Auth from '../../components/sections/auth/auth';

const SignInPage = () => (
  <Layout>
    <SEO title="Home" />
    <Auth formType="signin" />
  </Layout>
);

export default SignInPage;
